@import "../../../App.scss";

$summary_header_color: #050552;
$summary_details_label_color: rgba(5, 5, 82, 0.6);
$summary_details_value_color: rgba(5, 5, 82, 0.6);

@mixin summaryCommonFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#summary {
  &__container {
    background-color: $border_grey;
    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    color: $hyperblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px;
    box-shadow: 0px 0px 15px 0px rgba(85, 78, 241, 0.05);
  }
  &__header {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h1 {
        @include heading_inside_modal_bold();
        margin: 0px;
        color: $summary_header_color;
      }
    }
    &__details {
      @include summaryCommonFlex();
      gap: 12px 32px;
      > p {
        margin: 0px;
        @include base_font_and_inputs();
        text-align: left;
        color: $summary_details_label_color;
        > span {
          margin-left: 4px;
          font-weight: 500;
          color: $summary_details_value_color;
        }
      }
    }
  }
  &__body {
    &_container {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
    }
  }
  &__image {
    &_container {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
    &_header {
      @include overline_heading();
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
        margin-left: 4px;
        transform: rotate(270deg);
      }
      &.active {
        > img {
          transform: rotate(360deg);
        }
      }
    }
    &_carousal {
      &.empty {
        @include base_font_and_inputs();
      }
    }
  }
  &__metadata {
    &__container {
      margin-top: 32px;
    }
    &__header {
      display: flex;
      align-items: center;
      @include overline_heading();
      cursor: pointer;
      > img {
        margin-left: 4px;
        transform: rotate(270deg);
      }
      &.active {
        > img {
          transform: rotate(360deg);
        }
      }
    }
    &__details {
      margin-top: 20px;
      gap: 16px 24px;
      @include summaryCommonFlex();
      > p {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0px;
        @include base_font_and_inputs();
        color: $summary_details_label_color;
        > span {
          margin-left: 4px;
          font-weight: 500;
          color: $summary_details_value_color;
        }
      }
    }
  }
}
