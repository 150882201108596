@import "../../App.scss";

$aml_review_border_color: rgba(5, 5, 82, 0.2);
$aml_review_text_color: rgba(5, 5, 82, 0.8);
$aml_review_header_text_color: rgba(5, 5, 82, 0.4);
$aml_review_box_shadow_color: rgba(230, 230, 230, 0.95);
$aml_review_active_border_color: #554ef1;

$default_header_top_height: 56px;
$small_screen_header_top_height: 98px;
$default_content_padding_top: 10px;


@mixin aml_review_sections {
  margin-top: 20px;

  >h1 {
    font-size: 12px;
    color: $aml_review_header_text_color;
    text-transform: uppercase;
  }

  &_tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    >span {
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 100px;
      border: solid 0.5px $aml_review_border_color;
      color: $aml_review_text_color;
      margin: 15px 15px 0px 0px;
      font-weight: normal;

      &:hover {
        box-shadow: 0 0 15px 0 $aml_review_box_shadow_color;
      }

      &.active {
        border: 1px solid $aml_review_active_border_color;
        box-shadow: 0 0 15px 0 $aml_review_box_shadow_color;
      }
    }
  }
}

#record {
  &__container {
    height: 100%;
    width: 100%;
  }

  &__header {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 10000;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    background-color: rgba(255, 255, 255, 0.6);
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    // should this be dynamic ?
    height: $default_header_top_height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    height: 100%;
    overflow-y: auto;
    display: grid;
    column-gap: 10px;
    // Should this be dynamic ?
    grid-template-columns: 240px auto 415px;
    grid-template-areas: "left-panel body right-panel";
    align-items: start;
    padding: 0px 10px;
    padding-top: $default_header_top_height + $default_content_padding_top;
    padding-bottom: 16px;

    &.timeline {
      grid-template-columns: auto 415px !important;
      grid-template-areas: 'timeline right-panel' !important;
    }
  }

  &__left_panel {
    grid-area: left-panel;
    overflow: auto;
    position: sticky;
    display: flex;
    top: 0px;
    max-height: 100%;
  }

  &__right_panel {
    grid-area: right-panel;
    overflow: auto;
    position: sticky;
    top: 0px;
    max-height: 100%;

    &.timeline_shift {
      top: 30px;
    }
  }

  &__body {
    grid-area: body;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    scroll-behavior: smooth;
    padding-bottom: 16px;

    &__details {
      &__modules {
        &__aml_review {
          position: sticky;
          top: 0;
          width: 100%;
          display: flex;
          margin-top: 1em;
          padding: 15px 25px;
          background-color: white;

          >h1 {
            font-size: 14px;
            color: $aml_review_text_color;

            >span {
              font-style: italic;
            }
          }

          &__match_status {
            @include aml_review_sections();
          }

          &__risk_level {
            @include aml_review_sections();
          }

          &__comments {
            width: 300px;
            height: 96px;
            margin: 16px 0 24px;
            padding: 16px 8.5px 8.5px 16px;
            border-radius: 6px;
            border: solid 0.5px rgba(5, 5, 82, 0.2);
            background-color: rgba(85, 78, 241, 0.01);
          }

          &__push_comment {
            width: 50px;
            height: 50px;
            margin-left: auto;
            margin: 80px -10px 10px 50px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.loader {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@mixin collapse_side_panels_styles {
  #record {
    &__content {
      display: flex;
      column-gap: 10px;
      grid-template-columns: auto;
      grid-template-areas: "body";
      align-items: start;

      &.timeline {
        grid-template-columns: auto !important;
        grid-template-areas: 'timeline' !important;
      }
    }

    &__left_panel {
      width: 0%
    }

    &__right_panel {
      width: 0%
    }
  }
}

@mixin grid_side_panel_flexible_styles {
  #record {
    &__content {
      grid-template-columns: 20% auto 25%;

      &.timeline {
        grid-template-columns: auto 25% !important;
        grid-template-areas: 'timeline right-panel' !important;
      }
    }
  }
}


@mixin smaller_screens_common_header_styles {
  #record {
    &__header {
      flex-wrap: wrap;
      height: $small_screen_header_top_height;
    }

    &__content {
      padding-top: $small_screen_header_top_height + $default_content_padding_top;
    }
  }
}



/* Mobile Phones */
@media (max-width: 480px) {
  @include collapse_side_panels_styles();
  @include smaller_screens_common_header_styles();
}

/* Tablets in portrait mode */
@media (min-width: 481px) and (max-width: 768px) {
  @include collapse_side_panels_styles();
  @include smaller_screens_common_header_styles();
}

/* Tablets in landscape mode */
@media (min-width: 769px) and (max-width: 1024px) {
  @include grid_side_panel_flexible_styles()
}

/* Small Desktops and Laptops */
@media (min-width: 1025px) and (max-width: 1280px) {
  @include grid_side_panel_flexible_styles()
}

/* Medium Desktops */
@media (min-width: 1281px) and (max-width: 1440px) {
  /* Styles for medium desktops */
}

/* Large Desktops */
@media (min-width: 1441px) and (max-width: 1920px) {
  /* Styles for large desktops */
}

/* Extra Large Devices */
@media (min-width: 1921px) {
  /* Styles for extra large devices */
}