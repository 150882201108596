@import "../../../App.scss";

#toggle {
    margin-right: 18px;
    &__row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    p {
      @include sub_heading();
      text-align: left;
      margin: 0;
      width: 100%;
      color: rgba(5, 5, 82, 0.80);
    }
    &_button {
      width: 56px;
      height: 24px;
      padding: 2px;
      border-radius: 100px;
      display: flex;
      background-color: #e2e0e0;
      transition: 0.1s ease;
      margin-top: 2px;
      &_on {
        width: 56px;
        height: 24px;
        padding: 2px;
        border-radius: 100px;
        margin-top: 2px;
        display: flex;
        background-color: #01bf83;
        &:hover {
          cursor: pointer;
        }
      }
      &._disable{
        &:hover {
          cursor: not-allowed;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &_switch {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        transition: 0.1s ease;
        &_on {
          width: 20px;
          height: 20px;
          background-color: #fff;
          border-radius: 50%;
          margin-left: auto;
        }
      }
    }
  }