@import "../../../App.scss";

$icon_color: #373775;

@mixin application_status_tag_approved {
    background-color: #e3fcef;
    color: #006644;
}

@mixin application_status_tag_declined {
    background-color: #ffebe6;
    color: #bf2600;
}

@mixin application_status_classes {
    @include application_status_tag_common();
    background-color: #f4f5f7;
    color: rgba(5, 5, 82, 0.6);

    &.auto_approved {
        @include application_status_tag_approved();
    }

    &.auto_declined {
        @include application_status_tag_declined();
    }

    &.manually_approved {
        @include application_status_tag_approved();
    }

    &.manually_declined {
        @include application_status_tag_declined();
    }

    &.needs_review {
        background-color: #ffe19b59;
        color: #9a4c24;
    }

    &.user_cancelled {
        background-color: #f4f5f7;
        color: rgba(5, 5, 82, 0.6);
    }

    &.started {
        background-color: #deebff;
        color: #0747a6;
    }

    &.error {
        background-color: #4f148f1a;
        color: #4f148f;
    }
}

#right_panel {
    &__review_card {
        &__container {
            padding: 16px;
            width: 100%;
            border-radius: 6px;
            border: solid 0.5px rgba(5, 5, 82, 0.1);
            background-color: #fff;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &__assignee_container{
            font-family: 'Inter';
            width: 100%;
            padding: 7px 0px;
            margin-bottom: 10px;
            gap: 12px;
            border: 0.5px solid #0505521A;
            border-radius: 6px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
        }


        &__status {
            display: flex;
            flex-direction: row;
            align-items: center;

            >p {
                @include heading_inside_modal_semi_bold();
                margin: 0;
                color: #050552;
            }

            >span {
                margin-left: 8px;
                @include application_status_classes();

                &[aria-label] {
                    cursor: pointer;
                }
            }
        }

        &__error {
            display: flex;
            flex-direction: column;
            gap: 8px;

            >h4 {
                @include heading_inside_modal_semi_bold();
                margin: 0;
                color: #050552;
            }

            &__message {
                @include base_font_and_inputs();
                color: rgba(5, 5, 82, 0.8);
                display: flex;
                align-items: center;

                >img {
                    margin-right: 12px;
                }
            }
        }

        &__failure_reason {
            &_container {
                display: flex;
                flex-direction: column;

                >h4 {
                    margin: 0;
                    margin-bottom: 6px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #050552;
                }
            }

            &__text{
                &_container {
                    font-size: 14px;
                    font-style: italic;
                    color: rgba(5, 5, 82, 0.8);
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
    
                    >img {
                        margin-right: 12px;
                    }
                }
            }
        }

        &__flags {
            display: flex;
            flex-direction: column;
            gap: 8px;

            >h4 {
                @include heading_inside_modal_semi_bold();
                margin: 0;
                color: #050552;
            }

            &__empty {
                @include base_font_and_inputs();
                color: rgba(5, 5, 82, 0.8);
            }

            &__flag {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                border: solid 0.5px transparent;
                padding: 8px;
                cursor: pointer;

                >svg {
                    >g {
                        fill: $icon_color;
                    }

                    >path {
                        stroke: $icon_color;
                    }

                    margin-right: 11px;
                    height: 16px;
                    width: 16px;
                    position: relative;
                    flex-shrink: 0;
                }

                >p {
                    @include base_font_and_inputs();
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin: 0;
                    color: rgba(5, 5, 82, 0.8);

                    >span {
                        margin-left: 8px;
                    }
                }


                &.active,
                &:hover {
                    border-radius: 6px;
                    border: solid 0.5px rgba(85, 78, 241, 0.2);
                    background-color: rgba(85, 78, 241, 0.1);
                }
            }
        }
    }

    &__sub_panel_wrapper {
        background-color: $border_grey;
        padding: 16px;
        margin-top: 10px;
    }
}

.review_tags_card {
    &__container {
        padding: 0px;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    &__header {
        padding-bottom: 16px;
        @include heading_inside_modal_semi_bold();
        color: #050552;
        border-bottom: solid 0.5px rgba(5, 5, 82, 0.1);
    }

    &__body {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow: auto;
    }

    &__section {
        &__title {
            @include base_font_and_inputs();
            font-weight: 500;
            color: rgba(5, 5, 82, 0.8);
            margin-bottom: 16px;
        }
    }

    &__tag_group {
        &__title {
            @include small_bold_font();
            color: rgba(5, 5, 82, 0.4);
            margin-bottom: 12px;
        }

        &__tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px 24px;
            margin-bottom: 16px;

            &__tag_button {
                padding: 8px 12px !important;
                border-radius: 100px !important;
            }
        }
    }
}

.assignee_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.assignee_body {
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 20px;
}

.current_assignee_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 90%;
    border-radius: 4px;
    padding-right: 10px;
    margin: 10px 5px;
    height: 25px;
    pointer-events: none;

    > p {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        margin-left: 12px;
        color: rgba(5, 5, 82, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.current_assignee_container_with_permissions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 90%;
    border-radius: 4px;
    padding-right: 10px;
    margin: 10px 5px;
    height: 25px;

    > p {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        margin-left: 12px;
        color: rgba(5, 5, 82, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    > button {
        background-color: transparent;
        margin-left: 5px;
        cursor: pointer;
        color: rgba(85, 78, 241, 1);
    }
    &:hover {
        background: rgba(5, 5, 82, 0.03);
        cursor: pointer;
    }
}

.assignee_header {
    height: 24px;
    font-family: Inter;
    font-weight: 600;
    color: #050552;
    padding-left: 20px;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 12px;
}

.current_assignee_container_active{
    background: rgba(5, 5, 82, 0.03);
}