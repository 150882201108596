@import "../../../App.scss";

#header {
    &__left {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 24px;

        >p {
            margin: 0px;
            margin-left: 16px;
            @include modal_and_drawer_header();
            font-weight: 500;
            color: #050552;
        }
    }

    &__right {
        display: flex;
        flex-direction: row;
        padding-right: 24px;

        &_actions {
            padding-right: 17px;
            border-right: 0.5px solid rgba(5, 5, 82, 0.2);

            &_select>.common_select_button {
                padding: 8px;
            }
        }

        &_nav {
            margin-left: 17px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            >span {
                @include sub_heading();
                color: rgba(5, 5, 82, 0.8);
            }


            >button {
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(5, 5, 82, 0.8);
                font-size: 14px;
                font-weight: normal;
                padding: 0;

                &:hover {
                    cursor: pointer;
                }
            }

            &_prev_button {
                >img {
                    margin-right: 8px;
                }
            }

            &_next_button {
                >img {
                    margin-left: 8px;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.actions_icon {
    transform: none !important;
    margin-right: 8px;
}

@mixin smaller_screens_common_header_styles {
    #header {
        &__left {
            padding: 10px;
            width: 100%;

            >p {
                font-size: 14px;
                margin: 0px auto;
            }
        }

        &__right {
            padding: 10px;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
}

/* Mobile Phones */
@media (max-width: 480px) {
    @include smaller_screens_common_header_styles()
}


/* Tablets in portrait mode */
@media (min-width: 481px) and (max-width: 768px) {
    @include smaller_screens_common_header_styles();

    #header {
        &__left {
            >p {
                font-size: 16px;
            }
        }
    }
}

/* Tablets in landscape mode */
@media (min-width: 769px) and (max-width: 1024px) {
    /* Styles for tablets in landscape mode */
}

/* Small Desktops and Laptops */
@media (min-width: 1025px) and (max-width: 1280px) {
    /* Styles for small desktops and laptops */
}

/* Medium Desktops */
@media (min-width: 1281px) and (max-width: 1440px) {
    /* Styles for medium desktops */
}

/* Large Desktops */
@media (min-width: 1441px) and (max-width: 1920px) {
    /* Styles for large desktops */
}

/* Extra Large Devices */
@media (min-width: 1921px) {
    /* Styles for extra large devices */
}