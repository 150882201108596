#application_details_collapsible {
    font-family: "Inter" !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 8px 8px 0 0;
    background-color: #FFFFFF;
    border: 1px solid rgba(5, 5, 82, 0.10);

    &:hover {
        background-color: #FAFAFC;
        cursor: pointer;
    }

    &__left_column, &__right_column {
        margin: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__right_column {
        margin-right: 6px;
        transform: rotate(270deg);
    }

    &__text {
        margin-left: 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #050552;
    }

    &__children {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px;
        border-right: 1px solid #DEDFEE;
        border-left: 1px solid #DEDFEE;
        border-bottom: 1px solid #DEDFEE;
        border-radius: 0 0 8px 8px;
        background-color: #F2F2F9;
    }

    .active {
        transform: rotate(360deg);
    }

    &__label {
        display: flex;
        margin-left: 10px;
    }
}

.border_radius_enabled {
    border-radius: 8px !important;
    box-shadow: 0px 2px 5px 0px rgba(5, 5, 82, 0.04);
}
  
.border_radius_default {
    border-radius: 8px 8px 0 0 !important;
}